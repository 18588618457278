import { Alert, Button, Col, Form, Row, Card } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../application/store/useStore";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { RootState } from "../../../../application/store";
import { Controller, ValidateResult, useForm } from "react-hook-form";
import { RegisterOrganisationCredentialsDto } from "../../../../application/features/security-service/models/register-credentials-dto";
import { checkUserExistsAsync, registerAsync } from "../../../../application/features/security-service/securityServiceThunk";
import "../Auth.css";
import logo from "../../../../asstes/images/logo.svg";
import { getCountriesAsync } from "../../../../application/features/staticData-service/saticDataServiceThunk";
import MymonXValidation from "../../../components/mymonx-validation";
import { RegisterOrganisationDto } from "../../../../application/features/org-service/models/register-organisation-dto";
import { postRegisterOrganisationAsync } from "../../../../application/features/org-service/orgServiceThunk";
import { RegisterOrganisationResultDto } from "../../../../application/features/org-service/models/organisation-result-dto";
import MymonXReqAsteric from "../../../components/mymonx-req-asteric";
import { addWarningNotification } from "../../../../application/features/common/notificationSlice";
import moment from 'moment-timezone';

const Register = () => {
    const { currentUser, apiError } = useAppSelector((state: RootState) => state.securityService);
    const { contry_data } = useAppSelector((state: RootState) => state.staticDataService);
    const [timezones, setTimezones] = useState<string[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<string>('');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser !== undefined && currentUser.email != '') {
            navigate('/portal/dashboard');
        }
    }, [currentUser]);

    useEffect(() => {
        dispatch(getCountriesAsync({ page: 1, per_page: 10 }))

    }, [dispatch])
    useEffect(() => {
        if (selectedLocation) {
            const selectedCountry = contry_data.find((country: any) => country.sid === selectedLocation);
            if (selectedCountry) {
                const isoCode = selectedCountry.iso_a2_code.toUpperCase();
                try {
                    const timezones = moment.tz.zonesForCountry(isoCode);
                    setTimezones(timezones);
                } catch (error) {
                    console.error(`Error fetching timezones for ${isoCode}:`, error);
                }
            }
        }
    }, [selectedLocation, contry_data]);
    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        reset,
    } = useForm<RegisterOrganisationCredentialsDto>({
        defaultValues: {
            organisation_name: "",
            location: "",
            timezone: "",
            username: '',
            first_name: '',
            last_name: '',
            password: '',
            confirm_password: "",
        }
    })

    const onSubmit = (registerCredentials: RegisterOrganisationCredentialsDto) => {


        dispatch(checkUserExistsAsync(registerCredentials.username)).then(res => {
           
            if (res.payload) {
                reset({
                    organisation_name: "",
                    location: "",
                    timezone: "",
                    username: '',
                    first_name: '',
                    last_name: '',
                    password: '',
                    confirm_password: "",
                });
                dispatch(addWarningNotification("User already exists"))
                return;
            } else {
                const registerOrg = {
                    name: registerCredentials.organisation_name,
                    location: registerCredentials.location,
                    timezone: registerCredentials.timezone
                } as RegisterOrganisationDto;

                dispatch(postRegisterOrganisationAsync(registerOrg)).then((res: any) => {
                    
                    if (res.payload && res.payload as RegisterOrganisationResultDto) {
                        const result = res.payload as RegisterOrganisationResultDto;
                        registerCredentials.organisation_id = result.organisation_id;
                        dispatch(registerAsync(registerCredentials)).then(res => {
                           
                            if (res.payload) {

                                if ((res.payload as any).user_sid !== null && (res.payload as any).user_sid !== undefined) {

                                    navigate('/auth/register-success')
                                }
                                else {
                                    reset({
                                        organisation_name: "",
                                        location: "",
                                        timezone: "",
                                        username: '',
                                        first_name: '',
                                        last_name: '',
                                        password: '',
                                        confirm_password: "",
                                    });
                                    dispatch(addWarningNotification("User already exists"))
                                }
                            }
                        })
                    }
                })
            }
        })

    };

    const validatePassword = (password: string): ValidateResult => {
        if (password.length < 8)
            return 'Password should be minimum 8 char length';
        if (!/[A-Z]+/.test(password))
            return 'Password must have one upper case';
        if (!/[a-z]+/.test(password))
            return 'Password must have one lower case';
        if (!/[0-9]+/.test(password))
            return 'Password must have one number';
        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password))
            return 'Password must have one special char';
    };

    const validateConfirmPassword = (confirmPassword: string): ValidateResult => {
        if (watch("password") !== confirmPassword)
            return 'Password and confirm password should be same';
    };
    const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setSelectedLocation(value); // Update local state
        // No need to call setValue here since react-hook-form's Controller handles it
    };
    return (
        <Row className="h-100">
            {
                apiError && apiError.status === 401 && (
                    <>
                        <Alert security="error">{apiError.error}</Alert>
                    </>
                )
            }
            <Col className="col-12" >
                <Row className="justify-content-end">
                    <Col className="col-lg-4 col-md-5 text-end">
                        <a href="#"><img className="img-fluid" src={logo} alt="Logo" /></a>
                    </Col>
                </Row>
            </Col>

            <Col className="col-12" >
                <Row>
                    <Col className="col-lg-4 col-md-6">
                        <Card >
                            <Card.Body>
                                <h3 className="text-center my-3">Register</h3>
                                <Form onSubmit={handleSubmit(onSubmit)} id="registerForm" noValidate>
                                    <Form.Group className="mb-3" controlId="registerForm.organisation_name">
                                        <Form.Label>
                                            Organisation Name <MymonXReqAsteric />
                                        </Form.Label>
                                        <Controller
                                            name="organisation_name"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'organisation name is required'
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Control type="text" onChange={onChange} value={value} placeholder="Organisation Name" />
                                            )}
                                        />
                                        <MymonXValidation fieldError={errors.organisation_name} />

                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="registerForm.location">
                                        <Form.Label>Location <MymonXReqAsteric /></Form.Label>
                                        <Controller
                                            name="location"
                                            control={control}
                                            rules={{ required: "Location is required" }}
                                            render={({ field }) => (
                                                <Form.Select {...field} aria-label="Default select example"
                                                    onChange={(e) => {
                                                        field.onChange(e); // Ensure react-hook-form updates its state
                                                        handleLocationChange(e); // Run your custom logic
                                                    }}>
                                                    <option>Select Location</option>
                                                    {contry_data.map((item: any) => (
                                                        <option key={item.sid} value={item.sid}>{item.iso_name}</option>
                                                    ))}
                                                </Form.Select>
                                            )}
                                        />
                                        <MymonXValidation fieldError={errors.location} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="registerForm.location">
                                        <Form.Label>Timezone <MymonXReqAsteric /></Form.Label>
                                        <Controller
                                            name="timezone"
                                            control={control}
                                            rules={{ required: "Timezone is required" }}
                                            render={({ field }) => (
                                                <Form.Select {...field} aria-label="Default select example"
                                                >

                                                    <option>Select Timezone</option>
                                                    {timezones.map((zone, index) => (
                                                        <option key={index} value={zone}>{zone}</option>
                                                    ))}
                                                </Form.Select>
                                            )}
                                        />
                                        <MymonXValidation fieldError={errors.timezone} />
                                    </Form.Group>
                                    <h5>Admin User</h5>
                                    <Form.Group className="mb-3" controlId="registerForm.first_name">
                                        <Form.Label>User FirstName <MymonXReqAsteric /></Form.Label>
                                        <Controller
                                            name="first_name"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'First name is required'
                                                },
                                                // pattern: {
                                                //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                //     message: 'Invalid email address',
                                                // },
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Control type="text" onChange={onChange} value={value} placeholder="Enter you First Name" />
                                            )}
                                        />
                                        <MymonXValidation fieldError={errors.username} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="registerForm.last_name">
                                        <Form.Label>User Last Name <MymonXReqAsteric /></Form.Label>
                                        <Controller
                                            name="last_name"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Last name is required'
                                                },
                                                // pattern: {
                                                //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                //     message: 'Invalid email address',
                                                // },
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Control type="text" onChange={onChange} value={value} placeholder="Enter you Last Name" />
                                            )}
                                        />
                                        <MymonXValidation fieldError={errors.username} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="registerForm.username">
                                        <Form.Label>User Email <MymonXReqAsteric /></Form.Label>
                                        <Controller
                                            name="username"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'email is required'
                                                },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: 'Invalid email address',
                                                },
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Control type="email" onChange={onChange} value={value} placeholder="Enter you Email" />
                                            )}
                                        />
                                        <MymonXValidation fieldError={errors.username} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="registerForm.Password">
                                        <Form.Label>Password <MymonXReqAsteric /></Form.Label>
                                        <Controller
                                            name="password"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'password is required'
                                                },
                                                validate: (value: string) => validatePassword(value)
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Control type="password" onChange={onChange} value={value} placeholder="*******" />
                                            )}
                                        />
                                        <MymonXValidation fieldError={errors.password} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="registerForm.ConfirmPassword">
                                        <Form.Label>Verify Password <MymonXReqAsteric /></Form.Label>
                                        <Controller
                                            name="confirm_password"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'confirm password is required'
                                                },
                                                validate: (value: string) => validateConfirmPassword(value)
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Control type="password" onChange={onChange} value={value} placeholder="*******" />
                                            )}
                                        />
                                        <MymonXValidation fieldError={errors.confirm_password} />
                                    </Form.Group>
                                    <Button as="input" className="w-100 mb-3" variant="primary" type="submit" value="Register" />{' '}
                                </Form>
                                <div className="text-center">
                                    <a href="/auth/login" className="text-center text-decoration-none my-2 d-block"> Already have an account? Log In</a>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Col>


        </Row>



    )
}

export default Register;